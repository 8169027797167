.container {
  width: calc(100vw);
  @apply flex pb-1 px-8px overflow-x-auto;
}

@media (min-width: 1024px) {
  .container {
    width: auto;
  }
}
